<template>
  <div class="animated fadeIn">
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <v-row>
          <v-col>
            <h3>
              مدیریت بیمه ها
            </h3>
          </v-col>
          <v-col>
            <v-btn
              class="primary-btn"
              style="float: left"
              @click="OpenNewInsurance()"
            >
              <v-icon left>add</v-icon>
              <h6 class="mt-1">بیمه جدید</h6>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card class="cards pa-3">
        <v-card-text>
          <div>
            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              single-line
              hide-details
              clearable
              rounded
              filled
              class="text-right w-80 mb-2 search-input"
            ></v-text-field>
            <b-table
              responsive
              show-empty
              :fields="Fields"
              :items="Items"
              empty-text="بیمه‌ای برای نمایش وجود ندارد"
              empty-filtered-text="بیمه‌ای برای نمایش وجود ندارد"
              :busy="Busy"
              :filter="Filter"
              :current-page="CurrentPage"
              :per-page="PerPage"
              @filtered="onFiltered"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <template v-slot:cell(operation)="data">
                <div style="text-align: center; vertical-align: middle">
                  <v-btn
                    class="primary-btn pa-3"
                    @click="gotoEditInsurance(data.item)"
                    ><v-icon>
                      edit</v-icon></v-btn
                  >
                </div>
              </template>
              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(TotalRows / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
            ></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog width="500" v-model="newInsuranceDialog">
      <v-card>
        <v-card-title>
          <h3>افزودن بیمه</h3>
        </v-card-title>
        <v-card-text>
          <br />
          <v-text-field
            label="نام بیمه"
            outlined
            dense
            v-model="insurance.name"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="busy"
            class="red-btn"
            outlined
            @click="newInsuranceDialog = false"
          >
            لغو
          </v-btn>
          <v-btn
            :disabled="busy || insurance.name == ''"
            class="primary-btn"
            outlined
            @click="addInsurance()"
          >
            افزودن
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      CurrentPage: 1,
      PerPage: 10,
      TotalRows: "",
      Filter: "",
      Busy: true,
      busy: false,
      Fields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام بیمه" },
        { key: "status", label: "وضعیت" },
        { key: "operation", label: "جزییات " }
      ],
      Items: [],
      newInsuranceDialog: false,
      insurance: {}
    };
  },

  methods: {
    OpenNewInsurance() {
      // this.$router.push("/admin/newInsurance");
      this.insurance = {name:''};
      this.newInsuranceDialog = true;
    },
    addInsurance() {
      this.busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/insurances/new",
          {
            name: this.insurance.name
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token")
            }
          }
        )
        .then(res => {
          this.busy = false;
          if (res.status == 201) {
            this.toast("افزودن بیمه", "success");
            this.newInsuranceDialog = false;
            this.showInsuranceInfo();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch(err => {
          this.toast("خطا: " + err.response.data, "error");

          this.busy = false;
        });
    },
    gotoEditInsurance(item) {
      this.$router.push("/admin/editInsurance/" + item.id);
    },
    showInsuranceInfo() {
      //NOTE getting insurance info's data
      this.$http
        .post(
          this.baseUrl + "/clinic/insurances/insurances",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token")
            }
          }
        )
        .then(res => {
          if (res.status == 200) {
            this.Items = res.data;
            this.TotalRows = this.Items.length;
            this.Busy = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.Busy = false;
          }
        })
        .catch(err => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    }
  },

  mounted() {
    this.showInsuranceInfo();
  }
};
</script>
